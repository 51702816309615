import Vue from 'vue'
import VueTheMask from 'vue-the-mask';
import App from './App.vue'

import './registerServiceWorker'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import { setupBaseURL, setupApiOnResponseError } from './services/api-recebimentoweb';
import { ROUTE_SIGN_IN } from './constants/ROUTES';


Promise.all([
  setupBaseURL(),
  setupApiOnResponseError(async (err) => {
    if (err.response?.status === 401 && store.getters['auth/accessToken']) {
      await store.dispatch('auth/unauthenticate');

      if (router.currentRoute.path === ROUTE_SIGN_IN.name) return;

      router.push({
        path: ROUTE_SIGN_IN.name,
        query: { origin: router.currentRoute.fullPath },
      });
    }

    throw err;
  }),

])
  .then(() => {
    store.dispatch('$_setup');
  })
  .then(() => {
    Vue.use(VueTheMask);
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  });

