/* eslint-disable no-unreachable */
import db from '@/plugins/dexie';
import axios from 'axios';
import { WSModel } from './wsmodels';
import { nomesTabelas, prefixos } from './tabelas';

let wsapi = null;
let wsConnection = null;
const wsParametros = {
  connectionId: null,
  conectado: false,
};
let interval = null;
const dados = [];

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export async function WsEnviaDados({ tipo, msg }) {
  const wsConnectionId = wsParametros.connectionId;
  const token = await getSessionStorage('auth:token', null);
  const obj = {
    msg,
    tipo,
    connectionId:
    wsConnectionId,
    token,
  };
  wsConnection.send(JSON.stringify(obj));
}

export async function WSDefaultRoute() {
console.log(`Rodando em => ${process.env.NODE_ENV}`);
if (process.env.NODE_ENV !== 'production') return;
  const { data } = await axios.get('/api-ws-base-url');
  wsapi = data;
}

export async function SetupWebSocket() {
  // wsapi = axios.get('/api-ws-base-url');
  return;
  if (process.env.NODE_ENV === 'production' && !wsConnection) {
    wsConnection = new WebSocket(wsapi);
  } else if (!wsConnection && !wsParametros.connectionId) {
    wsConnection = new WebSocket('ws://192.168.0.126:3000');
  } else {
    return;
  }
  wsParametros.conectado = true;
  wsConnection.onmessage = ((msg) => {
    console.log('WebSocket: Mensagem: ', msg);
    let data;
    try {
      data = JSON.parse(msg.data);
    } catch {
      return;
    } if (data.tipo === 'novoItem') {
      const entity = WSModel[data.data.tabela](data.data.novo);
      db[data.data.tabela].add(entity);
    } if (data.tipo === 'atualizaItem') {
      const entity = WSModel[data.data.tabela](data.data.novo);
      const campo = `${prefixos[data.data.tabela].prefixo}idnuvem`;
      const objWhere = { [campo]: data.data.novo.id };
      const itemUpdate = db[data.data.tabela].where(objWhere).first();
      itemUpdate.then((item) => {
        db.veiculo.update(item.id, entity);
      });
    } if (data.tipo === 'deletaItem') {
      const adeletar = data.data.deletar;
      const exclusao = db[adeletar.loetabela].where(
        `${prefixos[adeletar.loetabela].prefixo}idnuvem`,
      ).equals(adeletar.loeidnuvem).delete();
      exclusao.then((deletou) => {
        if (deletou) {
          db.logexclusao.clear();
          db.logexclusao.add(WSModel.logexclusao(adeletar));
        }
      });
    } if (data.tipo === 'novoid') {
      wsParametros.connectionId = data.data;
      const abastecimentos = db.movimentacaoabastecimento.where('moaidnuvem').equals('null').toArray();
      abastecimentos.then((tabela) => {
        tabela.forEach((abb) => {
          console.log(abb, 'zzzzzzzzzzzzzzzzzzz');
          WsEnviaDados({ tipo: 'postAbastecimento', msg: abb });
        });
      });
      nomesTabelas.forEach((item) => {
        const table = db[item.tabela].toArray();
        table.then((tabelaAtual) => {
          // eslint-disable-next-line arrow-body-style
          let datasTabelas = tabelaAtual.map((linhas) => {
            if (item.tabela === 'logexclusao') {
              return linhas.loedataexclusao;
            }
            return linhas[`${prefixos[item.tabela].prefixo}dataatualizacao`];
          });
          if (datasTabelas.length > 0) {
            // eslint-disable-next-line arrow-body-style
            datasTabelas = datasTabelas.reduce((anterior, atual) => {
              return (anterior > atual) ? anterior : atual;
            });
          }
          // Tabelas a serem ignoradas no data Att.
          if (item.tabela !== 'movimentacaoabastecimento') {
            dados.push({ att: datasTabelas, tabela: item.tabela });
          } else {
            const movAbastecimentoPromise = db.movimentacaoabastecimento.toArray();
            movAbastecimentoPromise.then((itemPromise) => {
              itemPromise.forEach((abastecimento) => {
                if (abastecimento.moaidnuvem === 'null') {
                  WsEnviaDados({ tipo: 'postAbastecimento', msg: WSModel.movabastecimento(abastecimento) });
                }
              });
            });
          }
          if (dados.length === (nomesTabelas.length - 1)) {
            WsEnviaDados({ tipo: 'verificadataatt', msg: dados, connectionId: wsParametros.connectionId });
          }
        });
      });
    } else if (data.tipo === 'ping') {
      WsEnviaDados({ tipo: 'ping', msg: '' });
    } else if (data.tipo === 'updategeral') {
      data.data.forEach((alteracao) => {
        // Procurar alguma forma de trazer apenas uma propriedade
        const queryPromise = db[alteracao.tabela].toArray();
        queryPromise.then((itensTabelalocal) => {
          alteracao.entities.forEach((entity) => {
            if (alteracao.tabela === 'logexclusao') {
              const exclusao = db[entity.loetabela].where(
                `${prefixos[entity.loetabela].prefixo}idnuvem`,
              ).equals(entity.loeidnuvem).delete();
              exclusao.then((deletou) => {
                if (deletou) {
                  db.logexclusao.clear();
                  db.logexclusao.add(WSModel.logexclusao(entity));
                }
              });
            } else {
              const campoIdNuvem = `${prefixos[alteracao.tabela].prefixo}idnuvem`;
              let existe = false;
              itensTabelalocal.forEach((itemTabelaLocal) => {
                if (itemTabelaLocal[campoIdNuvem] === entity.id) {
                  existe = itemTabelaLocal.id;
                }
              });
              setTimeout(() => {
                if (existe) {
                  db[alteracao.tabela].update(existe, WSModel[alteracao.tabela](entity));
                } else {
                  db[alteracao.tabela].add(WSModel[alteracao.tabela](entity));
                }
              }, 0);
            }
          });
        });
      });
    } else if (data.tipo === 'retornoabastecimento') {
      const item = data.data.body;

      /* NÃO CAUSA CONFLITO COM DATAS DE OUTROS USUARIOS,
      JÁ QUE SOMENTE ESTE USUARIO VAI RECEBER A RESPOTA */
      const queryPromise = db.movimentacaoabastecimento.where('moadatacriacao').equals(String(item.moadatacriacao)).toArray();
      queryPromise.then((tabela) => {
        console.log(tabela, 'zzz');
        tabela.forEach((linha) => {
          db.movimentacaoabastecimento.update(linha.id, { moaidnuvem: item.id });
        });
      });
    }
  });
  wsConnection.onopen = ((msg) => {
    console.log(msg);
    console.log('WebSocket: Conexão Aberta');
    if (interval) {
      clearInterval(interval);
    }
  });

  wsConnection.onclose = ((msg) => {
    console.log(msg);
    console.log('WebSocket: Conexão Encerrada');
    wsParametros.conectado = false;
    wsParametros.connectionId = null;
    wsConnection = null;
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      if (navigator.onLine) {
        SetupWebSocket();
      }
    }, 3000);
  });

  wsConnection.onerror = ((msg) => {
    console.log(msg);
    console.log('Erro de conexão (WebSocket)');
  });
}

export async function CloseWebSocket() {
  wsConnection.close();
}
