<template>
  <v-card class='card-wrapper' rounded="lg" v-bind="$attrs" v-on="$listeners">
    <v-card-text class="text card">
      <div class="data">
        <slot name="data">
          <v-skeleton-loader width="33%" class="pt-1" type="text" />
        </slot>
      </div>
      <div class="quantidade">
        <slot name="quantidade">
          <v-skeleton-loader width="33%" class="pt-1" type="text" />
        </slot>
      </div>
      <div class="icones">
        <slot name="icones">
          <v-skeleton-loader width="25%" class="pt-1" type="text" />
        </slot>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AppCardAPagarSkeleton',

  props: {
    actions: Boolean,
  },
};
</script>

<style  scoped>

 .card-wrapper {
    border-radius: 0%  !important;
  }

   .card-wrapper:hover {
    cursor: default;
  }

  .text {
    display: flex;
    justify-content: space-between;
  }

  .card {
    width: 60vw;
    transition: 0.2s;
    padding: 15px 25px 15px 10px;
    font-size: 1.1rem;
  }

  .icones {
    text-align: right;
    width: 25%;
  }

  .quantidade {
    text-align: left;
    width: 35%;
  }

  .data {
    text-align: left;
    width: 40%;
  }

  .icones:hover {
    cursor: pointer;
    color: rgb(64, 228, 91) !important;
  }

  @media screen and (max-width: 800px) {

    .card {
      width: 90vw;
      transition: 0.2s;
      padding: 18px 0px 18px 30px;
      font-size: 1rem;
    }

  }

</style>
