import SnackbarEvent from '@/events/SnackbarEvent';

export default function actionResovler(resolvers) {
  return {
    data() {
      return {
        errors: {},
      };
    },

    methods: {
      ...Object.entries(resolvers)
        .reduce((actions, [key, action]) => ({
          ...actions,

          [key](params, options) {
            return this.$_actionResolver(action, params, options);
          },
        }), {}),

      $_actionResolver(action, params, options) {
        const { loading } = options ?? {};

        if (loading) {
          this.$set(this, loading, true);
        }

        try {
          return this.$store.dispatch(action, params);
        } catch (err) {
          if (err.response?.status === 422) {
            this.$set(this, 'errors', err.response.data.details.messages);
          } else {
            document.dispatchEvent(new SnackbarEvent({
              message: err.message ?? err,
            }));
          }

          throw err;
        } finally {
          if (loading) {
            this.$set(this, loading, false);
          }
        }
      },
    },
  };
}
