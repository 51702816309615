<template>
  <!-- <v-text-field v-if="number"
    v-bind="$attrs"
    v-on="$listeners"
    :outlined="outlined"
    :dense="!$_isMobile"
    class="mb-5"
    type="number"
    oninput="if(Number(this.texto) > Number(this.max)) this.texto  = this.max;"
  /> -->
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :outlined="outlined"
    :dense="!$_isMobile"
    :type="type"
    class="mb-5"
  />
</template>

<script>
export default {
  name: 'CoreNewTextField',

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: null,
    },
    max: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      texto: '',
    };
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
