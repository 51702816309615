<template>
  <div>
    <div class="container">
        <v-form ref="form" lazy-validation>
          <form-config
            ref="config"
            :formulario="formulario"
            tabela="apontamentoproducao"
            acaounica="updateByIdApontamento"
          />
            <div class="d-flex justify-center contaainer">
              <div class="linha-qr">
                <card-auto-complete ref="lote"
                  label="Lote P.A."
                  class="textfield n-center"
                  :dados="lotesdaop" 
                  v-model="formulario.appidaberturalote"
                  itemLabel="ablnrocontrole"  
                  item-value="ablidaberturalote"
                  :return-object="false"
                  :rules="rules.appidaberturalote"
                  hide-details
                  icon="mdi-qrcode-scan"
                  :action="$_onClickQR"
                  @keyup.enter="$refs.lote.$children[0].blur(); $refs.bag.focus()"
                />
                <v-autocomplete ref="bag"
                  label="Bag"
                  outlined
                  :disabled="!formulario.appidaberturalote"
                  background-color="white" 
                  class="numero-armazenamento n-center"
                  :items="apontamentosdolote"
                  v-model="objapontamento"
                  append-icon=""
                  item-text="appnumerobag"  
                  item-value="id"
                  :return-object="true"
                  @rules="rules.appnumerobag"
                  hide-details
                  @keyup.enter="$refs.bag.blur(); $refs.local.focus()"
                />
              </div>
              <v-card class="descricaoextra" v-if="lotecompleto?.abldescricao">
                <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.abldescricao}}</b>
                <v-divider></v-divider>
                <b class="centerextra" style="font-weight: 600;">{{lotecompleto?.ablgranulometria}}</b>
                <v-divider></v-divider>
                <b class="centerextra" style="font-weight: 600;">{{variedadeCompleta?.vardescricao}}</b>
                <v-divider v-if="apontamentoCompleto?.apppeso"></v-divider>
                <b class="centerextra" style="font-weight: 600;" v-if="apontamentoCompleto?.apppeso">
                  Peso: {{Math.trunc(apontamentoCompleto?.apppeso)}}</b>
              </v-card>
              <div class="linha-qr">
                <v-autocomplete ref="local"
                  label="Local"
                  outlined
                  background-color="white" 
                  class="n-center"
                  :items="locaistransferencia"
                  v-model="formulario.appidlocaltransferenciaproducao"
                  item-text="loadescricao"  
                  item-value="loaidlocalarmazenamento"
                  @rules="rules.appidlocaltransferenciaproducao"
                  @keyup.enter="$refs.local.blur(); $_submit"
                />
              </div>
            <br/>
            <v-btn color="primary" class="salvar" @click="$_submit" width="125px">
              <div v-if="!enviando">Salvar</div>
              <v-progress-circular v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-btn>
            </div>
              <!-- <v-btn @click="$_cancelar">Cancelar</v-btn> -->
        </v-form>
      <!-- <v-text-field></v-text-field> -->
      <br/>
    </div>
  </div>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import { ROUTE_PRODUCAO_CADASTRO, ROUTE_QR, ROUTE_TRANSFERENCIA_PRODUCAO  } from '@/constants/ROUTES';

import geraData from '@/utils/geradata'

import FormConfig from '@/components/form/components/form-config/Index.vue';
import CardAutoComplete from '@/components/core/card-auto-complete/Index.vue'

import { TRANSFERENCIA_STORAGE } from '@/constants/STORAGE'


// import { OPLOCAL_STORAGE, CADPRODUCAO_STORAGE } from '@/constants/STORAGE';

import db from '@/plugins/dexie';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}


// import AppCardAtividadeSkeleton from '../../components/app/card/atividade/Skeleton.vue';
import { SetupWebSocket } from '../../utils/ws/websocket';
import errorHandler from '@/utils/error-handler';

export default {
  name: 'ViewLoteC',

  components: {
    FormConfig,
    CardAutoComplete,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_PRODUCAO_CADASTRO,
      ],

      loading: true,
      lotes: null,

      lotesdaop: null,
      apontamentosdolote: null,
      locaistransferencia: null,
  
      proximolote: null,

      formulario: {
        appidaberturalote: null,
        appnumerobag: null,
        appdataalteracao: null,
        appstatus: 'P',
        appidnuvem: null,
        appidlocaltransferenciaproducao: null,
      },
      objapontamento: null,
      
      lotecompleto: null,
      apontamentoCompleto: null,
      variedadeCompleta: null,

      rules: {
        appidaberturalote: [ v => !!v  || 'Lote é obrigatório'],
        appnumerobag: [ v => !!v  || 'Bag é obrigatório'],
        appidlocaltransferenciaproducao: [ v => !!v || 'Local é obrigatório'],
      },

      // ESSA VIEW ESTÁ COM VARIAS FUNÇÕES A MAIS, RETIRAR DEPOIS

      existeapontamento: false,

      enviando: false,
    };
  },

  methods: {

    async $_setup() {
      this.lotesdaop = await db.aberturalote.orderBy('ablnrocontrole').toArray();
      this.locaistransferencia = await db.localarmazenamento.orderBy('loadescricao').toArray();

      const formulario = sessionStorage.getItem('formulario', null);
      if (formulario) {
        this.formulario = JSON.parse(formulario);
        this.$_verificaQR();
        sessionStorage.removeItem('formulario');
      }

      this.$refs.lote.$children[0].focus();
    },

    async $_submit() {
      if (this.enviando) {
        return;
      }
      if (this.$refs.form.validate()) {
        try {
          this.enviando = true;
          // === Producao ===
          // this.formulario.appdataalteracao = new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' });
          this.formulario.appdataalteracao = geraData();
          const res = await this.$refs.config.$_submit();
          if (res) {
            errorHandler('Status do apontamento alterado com sucesso!');
            sessionStorage.setItem(TRANSFERENCIA_STORAGE.qr, '');
            this.formulario = {   
              appnumerobag: null,
              appdataalteracao: null,
              appstatus: 'P',
              appidnuvem: null,
              appidlocaltransferenciaproducao: null,
            }
            this.objapontamento = null;
            this.lotecompleto = null;
            this.$refs.form.resetValidation();
            this.enviando = false;
          }
        } catch(e) {
          this.enviando = false;
          if(e.code == 'ERR_NETWORK'){
            errorHandler('Erro de conexão');
          } else {
            errorHandler(e);
          }
        }
      } else {
        errorHandler('Formulario inválido, verifique os dados e envie novamente');
      }
    },

    $_onClickQR() {
      sessionStorage.setItem('formulario', JSON.stringify(this.formulario));
      sessionStorage.setItem('rota-anterior', ROUTE_TRANSFERENCIA_PRODUCAO.path);
      setTimeout(() => {
        this.$router.push({
          ...this.$route,
          name: ROUTE_QR.name,
        });
      });
    },

    async $_verificaQR() {
      const qr = await getSessionStorage(TRANSFERENCIA_STORAGE.qr, '');
      const numerobag = await getSessionStorage(TRANSFERENCIA_STORAGE.numerobag, '');
      if (numerobag) {
        this.formulario.appnumerobag = numerobag;
      }
      if (qr && qr.length && qr !== 'null') {
        const lote  = (await db.aberturalote.where({ ablnrocontrole: qr }).toArray());
        if (!lote.length) {
          errorHandler('Lote não encontrado');
          return;
        }
        this.lotecompleto = null;
        this.lotecompleto = lote[0];
        this.formulario.appidaberturalote = lote[0].ablidaberturalote;
        this.$refs.peso.focus();
        if (typeof this.proximolote === 'object') {
          // this.$_adicionaLote(this.proximolote);
        } else {
          errorHandler('Erro ao adicionar novo lote')
        }
      }
    }
  },

  created() {
    this.$_setup();
    SetupWebSocket();
  },

  computed: {
    $$appidaberturalote() {
      return this.formulario.appidaberturalote;
    },

    $$appnumerobag() {
      return this.formulario.appnumerobag;
    }
  },

  watch: {
    $$appidaberturalote: {
      handler() {
        setTimeout(() => {
          if (this.formulario.appidaberturalote) {
            this.lotecompleto = null;
            this.apontamentoCompleto = null;
            this.variedadeCompleta = null;
            db.aberturalote.where({ ablidaberturalote: this.formulario.appidaberturalote }).toArray().then((item) => {
              this.lotecompleto = item[0];
              db.produto.where({ proidproduto: item[0].ablidproduto }).toArray().then((itemProduto) => {
                if (itemProduto[0]?.proidvariedade) {
                  db.variedade.where({ varidvariedade: itemProduto[0]?.proidvariedade }).toArray().then((itemVariedade) => {
                    this.variedadeCompleta = itemVariedade[0];
                  })
                }
              })
            })

            db.apontamentoproducao.where({ appidaberturalote: this.formulario.appidaberturalote }).toArray().then((item) => {
              const apontamentoFiltrado = item.filter((item) => item.appstatus !== 'P')

              this.apontamentosdolote = apontamentoFiltrado.map((itemMap) => {
                if (!itemMap.appstatus && itemMap.appnumerobag) {
                  return itemMap;
                } else {
                  return {
                    ...itemMap, appnumerobag: 'Nulo',
                  }
                }
              });
              
            })
          }
        }) 
      }
    },

    $$appnumerobag: {
      handler() {
        setTimeout(() => {
          this.apontamentoCompleto = this.apontamentosdolote.find((item) => (
            (item.id === this.objapontamento.id)
          ))
          if (this.apontamentoCompleto) {
            this.formulario.id = this.apontamentoCompleto.appidnuvem;
          }
        })
      }
    },

    objapontamento(value) {
      this.apontamentoCompleto = value;
      this.formulario.appnumerobag = value.appnumerobag;
      this.formulario.id = value.appidnuvem;
    },
  }
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }


  .center {
    width: 100%;
    text-align: center;
  }

  .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
  }

  .card-secador {
    width: 90%;
    text-align: center;
    margin: 20px;
  }

  .linhas {
    height: 21%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .finalizado {
    background-color: #4CAF50;
  }

  .iniciado {
    background-color: #6b93ff;
  }

  .disponivel {
    background-color: #b9b9b9;
  }

  .digitavel {
    margin-bottom: 25px;
  }

    .linha-menor {
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
    font-size: 13px;
  }

  .inner-card {
    display: flex;
    justify-content: space-between;
    height: 67%;
  }

  .container-inner-items {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container-inner-items-fill {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card-btn {
    transform: translateY(10px);
  }

  .txt-center {
    text-align: center;
  }

  .input-container {
      width: 60%;
  }

  .container {
    width: 93vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contaainer {
    width: 93vw;
    max-width: 250px;
    flex-wrap: wrap;
  }

  .textfield {
    width: 65%;
  }

  .n-center >>> input {
      text-align: center;
  }

  .n-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .nn-center >>> input {
      text-align: center;
  }

  .nn-center >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .linha-qr {
    display: flex;
  }

  .numero-armazenamento {
    width: 35%;
  }

  .descricaoextra {
    color: #3d68de;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 5px;
    background-color: #fdffd2;
  }

  .centerextra {
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
  }


  
</style>
