<template>
  <core-view class="flex-column" fill-height>
    <template>
    <div class="center" v-if="!loading">
        <!-- <form class="teste" v-if="estado = 'finalizado'"></form> -->
        <form class="teste">
          <div class="linha">
            <v-text-field label="OP" v-model="formulario.ordemproducao"
            outlined readonly />
            <v-text-field label="Data" v-model="formulario.dataordemproducao"
            outlined readonly />
          </div>
          <div class="linha">
            <v-text-field label="Lote" v-model="formulario.lote.ablnrocontrole"
            outlined readonly/>
            <btn-auto-complete :redirect="rotaFiltros[1]" label="Turno"
            :dados="autocomplete.turno" v-model="formulario.turno"
            itemLabel="tupidturno"
            />
          </div>
         <div class="linha-justificada">
            <new-text-field label="Descrição" v-model="$$_lotedesc"
            hide-details readonly
            outlined />
          </div>
          <div class="linha-justificada">
            <new-text-field label="Granulometria" v-model="$$_lotegranulo"
            outlined hide-details readonly />
          </div>
          <div class="linha-justificada container-data">
            <core-date-picker-field clearable
              v-model="formulario.datainicioprod"
              label="Inicio Produção"
            />
            <time-text-field v-model="formulario.timeinicioprod" outlined
            style="width: 30%;" @valor="$_alteraTimeInicio"
            />
            <v-btn height="55px" @click="$_atualizaData('datainicioprod','timeinicioprod')">
              <v-icon>mdi-timer-sync-outline</v-icon>
            </v-btn>
          </div>
          <div class="linha-menor">
            <new-text-field label="Quantidade" v-model="formulario.quantidade"
              outlined background-color="white" hide-details type="number"/>
          </div>
          <div class="linha">
          <btn-auto-complete :redirect="rotaFiltros[1]" label="Embalagem"
            :dados="autocomplete.embalagem" v-model="formulario.embalagem"
            itemLabel="proidproduto"
            hide-details />
            <btn-auto-complete :redirect="rotaFiltros[1]" label="Lote"
            :dados="autocomplete.loteembalagem" v-model="formulario.loteembalagem"
            itemLabel="ablnrocontrole"
            hide-details />
          </div>
          <div class="linha">
            <v-text-field label="Descrição" v-model="$$_embalagemdesc"
            outlined readonly />
          </div>
          <div class="linha-menor">
            <new-text-field label="Quantidade" v-model="formulario.quantidadeembalagem"
            outlined background-color="white" hide-details number/>
          </div>
          <div class="linha">
            <div class="reduzido">
              <btn-auto-complete label="Local"
              :dados="autocomplete.localarmazenamento" v-model="formulario.localarmazenamento"
              itemLabel="loacodigoarmazem"  hide-details
              />
            </div>
          <new-text-field label="Descrição" v-model="$$_localarmazenamentodesc"
            outlined hide-details readonly />
          </div>
          <div class="linha-justificada container-data">
            <core-date-picker-field clearable
              v-model="formulario.dataterminoprod"
              label="Termino Produção"
              hide-details
            />
            <time-text-field v-model="formulario.timeterminoprod"
            style="width: 30%;" hide-details outlined @valor="$_alteraTimeTermino"
            />
            <v-btn height="55px" @click="$_atualizaData('dataterminoprod', 'timeterminoprod')">
              <v-icon>mdi-timer-sync-outline</v-icon>
            </v-btn>
          </div>
          <br/>
          <div class="div-inferior">          
        <!-- <v-btn height=45 color="green botaoinferior" @click="edicao++" v-if="edicao === 1">
          Próximo
        </v-btn > -->
        <v-btn height=45 color="green botaoinferior" @click="$_salvaDados(
          [formulario.turno, formulario.datainicioprod, formulario.dataterminoprod, formulario.localarmazenamento, formulario.quantidade,
          formulario.quantidadeembalagem, formulario.embalagem, formulario.loteembalagem])">
          Salvar
        </v-btn>
          </div>
      </form>
    </div>
 </template>

</core-view>
</template>

<script>
// import errorHandler from '@/utils/error-handler';

// import {
//   ROUTE_LOTE,
// } from '@/constants/ROUTES';

import { OPLOCAL_STORAGE } from '@/constants/STORAGE';

import CoreView from '@/components/core/view/Index.vue';

import NewTextField from '@/components/core/new-text-field/Index.vue';
// import NewTextFieldMoney from '@/components/core/new-text-field-money/Index.vue';
import BtnAutoComplete from '@/components/core/btn-auto-complete_v2/Index.vue';
import TimeTextField from '@/components/core/time-text-field/Index.vue';
// import QrTextField from '@/components/core/qr-text-field/Index.vue';

import CoreDatePickerField from '@/components/core/editable-date-picker-field/Index.vue';

import moment from 'moment-timezone';

import $_itensTesteTabelaLocal from '@/utils/templatetabelas'
import { $_enviaApontamento } from '@/utils/atualizalocal'

import {
  getQueryValue,
} from '@/services/query-string';
import { CADASTRO_QUERIES } from '@/constants/QUERY_FILTERS';

import db from '@/plugins/dexie';
// import moment from 'moment';

import { SetupWebSocket } from '../../utils/ws/websocket';
import { ROUTE_LOTE } from '@/constants/ROUTES';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewCadastroProducao',

  components: {
    CoreView,
    NewTextField,
    BtnAutoComplete,
    // NewTextFieldMoney,
    CoreDatePickerField,
    TimeTextField,
    // QrTextField,
  },

  data() {
    return {
      rotaFiltros: [
      ],

      edicao: false,

      formulario: {
        ordemproducao: null,
        dataordemproducao: null,
        lote: null,
        turno: null,
        // descricao: null, --> Computed
        // granulometria: null, --> Computed
        datainicioprod: '',
        timeinicioprod: null,
        quantidade: null,
        embalagem: null,
        loteembalagem: null,
        // embalagemdesc: null, --> Computed
        quantidadeembalagem: null,
        localarmazenamento: null,
        // localarmazenamentodesc: null, --> Computed
        dataterminoprod: '',
        timeterminoprod: null,
      },

      autocomplete: {
        turno: null,
        lote: null,
        embalagem: null,
        loteembalagem: null,
        local: null,
      },

      idlocal: null,

      loading: true,

      filters: {
        createdFrom: String(new Date()),
      },

      mask: '##:##',
    };
  },

  computed: {
    $$_localarmazenamentodesc() {
      const temp = this.formulario;
      return temp.localarmazenamento?.loadescricao;
    },

    $$_embalagemdesc() {
      const temp = this.formulario;
      return temp.embalagem?.prodescricao;
    },

    $$_lotedesc() {
      const temp = this.formulario;
      return temp.lote?.abldescricao;
    },

    $$_lotegranulo() {
      const temp = this.formulario;
      return temp.lote?.ablgranulometria;
    },
  },

  methods: {

    // $_setOperador(valor) {
    //   sessionStorage.setItem(OPERADOR_STORAGE.tipo, valor);
    // },

    $_alteraTimeInicio(valor) {
      this.formulario.timeinicioprod = valor;
    },


    $_alteraTimeTermino(valor) {
      this.formulario.timeterminoprod = valor;
    },

    $_atualizaData(campo, tempo) {
      this.formulario[campo] = moment().format('YYYY-MM-DD');
      this.formulario[tempo] = moment().format('HH:mm');
    },

    $_itensTesteTabelaLocalCaller() {
      $_itensTesteTabelaLocal();
    },

    async $_salvaDados(aValidar) {

      // this.loading = true;

      let novoApontamentoProducao = null;
      let novoApontamentoProducaoNuvem = null;

      novoApontamentoProducao = {
        // appordemproducao: this.formulario.ordemproducao,
        applote: this.formulario.lote?.ablnrocontrole,
        appturno: this.formulario.turno?.tupidturno,
        appdescricao: this.formulario.lote?.abldescricao,
        appgranulometria: this.formulario.lote?.ablgranulometria,
        appdatainicio: this.formulario.datainicioprod,
        apptimeinicio: this.formulario.timeinicioprod,
        appestado: this.estado,
        appquantidade: this.formulario.quantidade,
        appembalagem: this.formulario.embalagem?.proidproduto,
        appdescricaoembalagem: this.formulario.embalagem?.prodescricao,
        apploteembalagem: this.formulario.loteembalagem?.ablnrocontrole,
        appquantidadeembalagem: this.formulario?.quantidadeembalagem,
        applocalarmazenamento: this.formulario.localarmazenamento?.loacodigoarmazem,
        appdescricaolocalarmazenamento: this.formulario.localarmazenamento?.loadescricao,
        appdatatermino: this.formulario.dataterminoprod,
        apptimetermino: this.formulario.timeterminoprod,
        // appestado: this.estado,
      };

      const itemlocal = (await db.apontamentoproducaolocal.where({
          id: this.idlocal
      }).toArray());

      const idordemproducao = Number(await getSessionStorage(OPLOCAL_STORAGE.idop, ''));


      let inicio = null;
      let final = null;
      if (this.formulario.datainicioprod && this.formulario.timeinicioprod) {
        inicio = this.formulario.datainicioprod + 'T' + this.formulario.timeinicioprod;
      }

      if (this.formulario.dataterminoprod && this.formulario.timeterminoprod) {
        final = this.formulario.dataterminoprod + 'T' + this.formulario.timeterminoprod;
      }

      const valida = this.$_validaCampos(aValidar);
      
      if (valida) {
          novoApontamentoProducaoNuvem = {
          appidordemproducao: idordemproducao,
          appidaberturaloteembalagem: this.formulario.loteembalagem.ablidaberturalote,
          appquantidadeembalagem: this.formulario.quantidadeembalagem,
          appdatainicio: inicio,
          appidlocalarmazenamento: this.formulario.localarmazenamento.loaidlocalarmazenamento, 
          appidaberturalote: this.formulario.lote.ablidaberturalote,
          appdatatermino: final,
          appidturno: this.formulario.turno.tupidturno,
          appquantidade: this.formulario.quantidade,
          appdataalteracao: null,
        };
      }

      if (novoApontamentoProducaoNuvem) { 
        novoApontamentoProducao.appestado = 'F'; 
      } else {
        novoApontamentoProducao.appestado = 'A'; 
      }
      
      await db.apontamentoproducaolocal.update(Number(itemlocal[0].id), novoApontamentoProducao);
      if (this.edicao) {
        await db.apontamentoproducao.add(novoApontamentoProducaoNuvem).then((id) => {
          db.apontamentoproducaolocal.update(Number(itemlocal[0].id), { appidapontamentonuvemlocal: id });
        })
      } else if (novoApontamentoProducaoNuvem) {
        await db.apontamentoproducao.update( Number(itemlocal[0].appidapontamentonuvemlocal), novoApontamentoProducaoNuvem);
      }

      $_enviaApontamento();

      this.$router.replace({ 
        ...this.$route,
        name: ROUTE_LOTE.name,
      });

      return;
    },

    $_validaCampos() {
      const { 

        /* 
          Existe uma regra no eslint que libera a criacao de variaveis sem uso em situacoes
          como essa (destruction + remocao de algumas propriedades especificas do objeto)
        */

        // eslint-disable-next-line no-unused-vars
        ordemproducao, 
        // eslint-disable-next-line no-unused-vars
        embalagem, 
        // eslint-disable-next-line no-unused-vars
        loteembalagem,
        // eslint-disable-next-line no-unused-vars
        quantidadeembalagem,
        // eslint-disable-next-line no-unused-vars
        dataordemproducao,
        // eslint-disable-next-line no-unused-vars
        lote,
        ...formulario
        } = this.formulario;
      const possuinulo = Object.values(formulario).find((item) => { return !item || '' })
      if (possuinulo === null) {
        return false; // Não possui todos os campos preenchidos
      } else if (possuinulo === undefined) {
        return true; // Possui todos os campos preenchidos
      }
    },

    FormataData(date) {
      // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
      return moment.tz(date, 'America/Sao_Paulo').local().format('DD/MM/YYYY hh:mm');
    },

    async $_findDb() {
      this.nro = await getSessionStorage(OPLOCAL_STORAGE.nro, '');
      this.edicao = Number(await getSessionStorage(OPLOCAL_STORAGE.emedicao, false));
  
      this.idlocal = await getQueryValue(CADASTRO_QUERIES.idlocal, '');
  
      this.idlocal = Number(this.idlocal);
      const local = (await db.apontamentoproducaolocal.where({
        id: this.idlocal
      }).toArray())[0];
      this.formulario.dataordemproducao = this.FormataData(local.appdata);
      try {
        this.autocomplete.turno = await db.turno.toArray();
        this.formulario.lote = (await db.aberturalote.where({
          ablnrocontrole: local.applote
        }).toArray())[0];
        this.autocomplete.embalagem = await db.produto.toArray();
        this.autocomplete.loteembalagem = await db.aberturalote.toArray();
        this.autocomplete.localarmazenamento = await db.localarmazenamento.toArray();
        this.estado = (await db.apontamentoproducaolocal.where({
          id: this.idlocal
        }).toArray())[0].appestado;
      } catch(err) {
        console.log(err);
      }
      await this.$_setupCampos();
      setTimeout(() => {
        this.loading = false;
      }, 0)
      return null;
    },

    async $_setupCampos() {
      const idapontamento = this.idlocal;
      const local = await db.apontamentoproducaolocal.where({ id: Number(idapontamento) }).first();
      console.log(local, 'UA');
      if (local?.appturno) {
        this.formulario.turno = (await db.turno.where({
          tupidturno: local.appturno
        }).toArray())[0];
      }
      this.formulario.datainicioprod = local?.appdatainicio ?? '';
      this.formulario.timeinicioprod = local?.apptimeinicio;
      this.formulario.quantidade = local?.appquantidade;
      if (local?.apploteembalagem) {
        this.formulario.loteembalagem = (await db.aberturalote.where({
          ablnrocontrole: local.apploteembalagem
        }).toArray())[0];
      }
      if (local?.appembalagem) {
        this.formulario.embalagem = (await db.produto.where({
          proidproduto: local.appembalagem
        }).toArray())[0];
      }
      this.formulario.quantidadeembalagem = local?.appquantidadeembalagem;
      if (local?.applocalarmazenamento) {
        this.formulario.localarmazenamento = (await db.localarmazenamento.where({
          loacodigoarmazem: local.applocalarmazenamento
        }).toArray())[0];
      }
      this.formulario.dataterminoprod = local?.appdatatermino ?? '';
      this.formulario.timeterminoprod = local?.apptimetermino;
    },
  },

  created() {
    this.$_findDb();
    SetupWebSocket();
  },

};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .linha {
    display: flex;
        /* border: 1px solid red; */
  }

  .linha-menor {
    display: flex;
    width: 50%;
        /* border: 1px solid red; */
  }

  .linha-justificada {
    display: flex;
    justify-content: space-around;
  }

  .div-inferior {
    display: flex;
    justify-content: space-between;
  }

  .botaoinferior {
    color: white;
  }

  .container-data > * {
    margin: 0px 10px 0px 10px;
  }

  .reduzido {
    width: 35%;
  }

</style>
