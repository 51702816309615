<template>
  <core-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :type="$_type"
    inputmode="text"
    :append-icon="$_icon"
    @click:append="$_onClickIcon"
  />
</template>

<script>
import CoreTextField from '../text-field/Index.vue';

export default {
  name: 'CorePasswordField',

  components: {
    CoreTextField,
  },

  data() {
    return {
      showPassword: false,
    };
  },

  computed: {
    $_type() {
      return this.showPassword ? 'numeric' : 'password';
    },

    $_icon() {
      return this.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline';
    },

  },

  methods: {
    $_onClickIcon() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
