<template>
  <v-text-field
    :rounded="rounded"
    v-bind="$attrs"
    v-on="$listeners"
    :outlined="outlined"
    :dense="!$_isMobile"
    class="mb-5"
  />
</template>

<script>
export default {
  name: 'CoreTextField',

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
