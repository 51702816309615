<template>
  <layout-default-appbar filterabl :backTo="rota">
      Lotes
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue';
import { ROUTE_PRODUCAO } from '@/constants/ROUTES';

export default {
  name: 'ViewFrotaAppBar',

  components: {
    LayoutDefaultAppbar,
  },

    data() {
    return {
      rota: ROUTE_PRODUCAO,
    }
  }

};
</script>
