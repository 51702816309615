export const GENERIC_QUERIES = {
  search: 'q',
  page: 'page',
  sort: 'sort',
};

export const ABASTECIMENTO_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
}

export const CADASTRO_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  prefixo: 'prefixo',
  nrocontrole: 'nrocontrole',
  nomelote: 'nomelote',
  idlocal: 'idlocal',
  nomeproducao: 'nomeproducao',
};

export const LOTES_QUERIES = {
  idordemproducao: 'idordemproducao',
};

export const CADORDEMPRODUCAO_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  nomefabrica: 'nomefabrica',
};

export const LOTES_FINALIZADOS_QUERIES = {
  nroaberturalote: 'nroaberturalote',
  idproducao: 'idproducao',
};