<template>
  <div>
    <div class="container">
      <div class="container-flex-center">
        <div>
        <core-dialog-btn label="Adicionar Lote" titulo="Novo Apontamento"
          color="green"
          @salvar="$_adicionaLote(proximolote)"
          @cancelar="proximolote = null"
          salvar='Criar'
          sair="Cancelar"
          :dialogprop='abrirdialog'>
          <template #conteudo>
            <div>
              <p>Selecione um lote para criar um novo apontamento.</p>
              <card-auto-complete label="Novo Lote"
              :dados="lotesdaop" 
              v-model="proximolote"
              itemLabel="ablnrocontrole"  hide-details
              icon="mdi-qrcode-scan"
              :action="$_onClickQR"
              />
            </div>
          </template>
        </core-dialog-btn>
        </div>
      </div>
      <!--  / / / / / / / / / / / / / / /  -->
      <div v-if="!loading">
        <div v-for="( item, index) in lotesMaisRecentes" :key="index">
          <div class="card-wrapper">
            <!--  / / / / / / / / / / / / / / /  -->
            <v-card class="card-secador" v-if="item.appestado === 'A'"> 
              <div class="linhas iniciado"><b>{{item.applote}}</b></div>
              <v-divider></v-divider>
              <div class="linha-menor">
                <span>Produto:</span>
                <span>{{item.appdescricao}}</span>
              </div>
              <v-divider></v-divider>
              <div class="linha-menor">
                <span>Granulometria:</span>
                <span>{{item.appgranulometria}}</span>
              </div>
              <v-divider></v-divider>
              <div class="linha-menor">
                <span>Quantidade:</span>
                <span>{{$_somaPeso(item.applote)}}kg</span>
              </div>
              <v-divider></v-divider>
              <div class="inner-card">
                <v-card class="container-inner-items">
                  <v-btn class="card-btn" @click="$_openCadastro(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <br/>
                  <span>Continuar</span>
                </v-card>
                <v-card class="container-inner-items" @click="$_openDetalhe(item.applote)">
                  <v-btn class="card-btn">
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                  <br/>
                  <span>Detalhes</span>
                </v-card>
              </div>
            </v-card>
            <!--  / / / / / / / / / / / / / / /  -->
            <v-card class="card-secador" v-else> 
              <div class="linhas finalizado"><b>{{item.applote}}</b></div>
              <v-divider></v-divider>
              <div class="linha-menor">
                <span>Produto:</span>
                <span>{{item.appdescricao}}</span>
              </div>
              <v-divider></v-divider>
              <div class="linha-menor">
                <span>Granulometria:</span>
                <span>{{item.appgranulometria}}</span>
              </div>
              <v-divider></v-divider>
              <div class="linha-menor">
                <span>Quantidade:</span>
                <span>{{$_somaPeso(item.applote)}}kg</span>
              </div>
              <v-divider></v-divider>
              <div>
                <div class="inner-card">
                  <v-card class="container-inner-items">
                    <v-btn class="card-btn" @click="$_openCadastro(item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <br/>
                    <span>Apontamento</span>
                  </v-card>
                  <v-card class="container-inner-items">
                    <v-btn class="card-btn" @click="$_openDetalhe(item.applote)">
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    <br/>
                    <span>Detalhes</span>
                  </v-card>
                </div>
              </div>
            </v-card>
            <!--  / / / / / / / / / / / / / / /  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import { setQueryValue } from '@/services/query-string';

import { CADASTRO_QUERIES } 
from '@/constants/QUERY_FILTERS';

import { ROUTE_PRODUCAO_CADASTRO, ROUTE_LOTES_FINALIZADOS, ROUTE_QR  } from '@/constants/ROUTES';

import { OPLOCAL_STORAGE } from '@/constants/STORAGE'

import CoreDialogBtn from '@/components/core/dialog-btn/Index.vue';
import CardAutoComplete from '@/components/core/card-auto-complete/Index.vue'

// import { OPLOCAL_STORAGE, CADPRODUCAO_STORAGE } from '@/constants/STORAGE';

import db from '@/plugins/dexie';

import moment from 'moment-timezone';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}


// import AppCardAtividadeSkeleton from '../../components/app/card/atividade/Skeleton.vue';
import { SetupWebSocket } from '../../utils/ws/websocket';
import errorHandler from '@/utils/error-handler';

export default {
  name: 'ViewFrota',

  components: {
    CoreDialogBtn,
    CardAutoComplete,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_PRODUCAO_CADASTRO,
      ],

      fabrica: null,
      loading: true,
      lotes: null,
      lotesdaop: null,
      lotesfechados: null,
      proximolote: null,
      idordemproducao: null,

      itensComEsseNro: null,

      lotesMaisRecentes: [],

      abrirdialog: false,

    };
  },

  methods: {

    async $_setup() {
      this.idordemproducao = Number(await getSessionStorage(OPLOCAL_STORAGE.idop, ''));
      this.lotesdaop = await db.aberturalote.toArray();
      this.lotes = await db.apontamentoproducaolocal.where({ 
        appordemproducao: this.idordemproducao }).sortBy('applote');
      const nrosapontamento = this.lotes.map(item => item.applote);

      // Pega apenas o apontamento mais recente de cada lote para exibir;
      this.lotesMaisRecentes = [];
      this.lotes.forEach((item) => {
        const valor = this.lotesMaisRecentes.find(el => el?.applote === item.applote);
        if (valor) {
          if (item.appdata > valor.appdata) {
            const index = this.lotesMaisRecentes.findIndex(el => el.applote === item.applote);
            this.lotesMaisRecentes[index] = item;
          }
        } else {
          this.lotesMaisRecentes.push(item);
        }
      });
      // =============================================================== //

      this.lotesdaop = this.lotesdaop.filter(item =>  !nrosapontamento.includes(item.ablnrocontrole));
      this.loading = false;

    },

    $_somaPeso(nro) {
      const itensComEsseNro = this.lotes.filter((item) => {
        return item.applote === nro;
      })

      const soma = itensComEsseNro.reduce((acc, cur) => {
        return acc +  Number(cur.appquantidade);
      }, 0);
      return soma;
    },

    $_adicionaLote(item, retorno) {
      let novoApontamentoProducaoVazio = {
          appordemproducao: this.idordemproducao,
          appdata: new Date(),
          applote: item.ablnrocontrole,
          appturno: null,
          appdescricao: item.abldescricao,
          appgranulometria: item.ablgranulometria,
          appdatainicio: null,
          appquantidade: null,
          appembalagem: null,
          appquantidadeembalagem: null,
          apploteembalagem: null,
          applocalarmazenamento: null,
          appdatatermino: null,
          appestado: null,
        };
      if (retorno) {
        novoApontamentoProducaoVazio = {
          appordemproducao: this.idordemproducao,
          appdata: new Date(),
          applote: item.applote,
          appturno: null,
          appdescricao: item.appdescricao,
          appgranulometria: item.appgranulometria,
          appdatainicio: null,
          appquantidade: null,
          appembalagem: null,
          appquantidadeembalagem: null,
          apploteembalagem: null,
          applocalarmazenamento: null,
          appdatatermino: null,
          appestado: null,
        };
      }
      const id = db.apontamentoproducaolocal.add( novoApontamentoProducaoVazio );
      novoApontamentoProducaoVazio.id = id;
      if (retorno) {
        return novoApontamentoProducaoVazio;
      } else {
        this.$_setup();
      }
      sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
    },

    FormataData(date, formato) {
      // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
      return moment.tz(date, 'America/Sao_Paulo').local().format(formato);
    },

    $_openDetalhe(nro) {
      sessionStorage.setItem(OPLOCAL_STORAGE.nro, nro);
      this.$router.replace({ 
        ...this.$route,
        name: ROUTE_LOTES_FINALIZADOS.name,
      });
    },

    $_openCadastro(lote) {    
      sessionStorage.setItem(OPLOCAL_STORAGE.emedicao, false);
      sessionStorage.setItem(OPLOCAL_STORAGE.idapontamento, lote.id);
      const itensComEsseNro = this.lotes.filter((item) => {
        return item.applote === lote.applote;
      })
      const abertos = itensComEsseNro.filter((item) => {
        return item.appestado !== 'F'
      })
      let novoLote = lote;
      if (abertos.length === 0) {
        novoLote = this.$_adicionaLote(novoLote, true);
        const idPromise = novoLote.id;
        idPromise.then((id) => {
          setQueryValue(CADASTRO_QUERIES.idlocal, novoLote.id);
          this.$router.replace({ 
            ...this.$route,
            name: ROUTE_PRODUCAO_CADASTRO.name,
            idlocal: id,
        });
        })
      } else {
        setQueryValue(CADASTRO_QUERIES.idlocal, novoLote.id);
        this.$router.replace({ 
          ...this.$route,
          name: ROUTE_PRODUCAO_CADASTRO.name,
          idlocal: novoLote.id,
        });
      }
    },

    $_onClickQR() {
      setTimeout(() => {
        this.$router.push({
          ...this.$route,
          name: ROUTE_QR.name,
        });
      });
    },

    async $_verificaQR() {
      const qr = await getSessionStorage(OPLOCAL_STORAGE.qr, '');
      if (qr) {
        console.log(qr);
        this.proximolote = (await db.aberturalote.where({ ablnrocontrole: qr }).toArray())[0];
        if (typeof this.proximolote === 'object') {
          this.$_adicionaLote(this.proximolote);
        } else {
          errorHandler('Erro ao adicionar novo lote')
        }
      }
      sessionStorage.setItem(OPLOCAL_STORAGE.qr, '');
    }
  },

  created() {
    this.$_setup();
    this.$_verificaQR();
    SetupWebSocket();
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .card {
    padding: 3px;
    margin: 15px 10px 0px 10px
  }

  .card-secador {
    width: 90%;
    text-align: center;
    margin: 20px;
  }

  .linhas {
    height: 21%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .finalizado {
    background-color: #4CAF50;
  }

  .iniciado {
    background-color: #6b93ff;
  }

  .disponivel {
    background-color: #b9b9b9;
  }

    .linha-menor {
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
    font-size: 13px;
  }

  .inner-card {
    display: flex;
    justify-content: space-between;
    height: 67%;
  }

  .container-inner-items {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container-inner-items-fill {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card-btn {
    transform: translateY(10px);
  }

  .container-flex-center {
    text-align: right;
  }

  .txt-center {
    text-align: center;
  }

  .tabela {
    width: 100%;
    padding: 0px;
    background-color: rgb(136, 136, 136);
  }
  .header-tabela {
    width: 100%;
    background-color: #a8e4a1;
    font-size: 13px;
  }
  .col1-header, .col1, .col2-header, .col2 {
    width: 32%;
    text-align: center;
  }
  .col3-header, .col3 {
    width: 36%;
    text-align: center;
  }
  .claro {
  background-color: #f4faf5;
  }
  .tabela-interna {
  width: 100%;
  font-size: 12px;
  } 

</style>
