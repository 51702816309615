<template>
  <layout-default-appbar :back-to="rotafinalizados">
    {{nomelote}}
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue';

import {
  getQueryValue,
} from '@/services/query-string';


import { ROUTE_LOTES_FINALIZADOS } from '@/constants/ROUTES';
import { CADASTRO_QUERIES } from '@/constants/QUERY_FILTERS';

export default {
  name: 'ViewFrotaAppBar',

  components: {
    LayoutDefaultAppbar,
  },

  data() {
    return {
      rotafinalizados: ROUTE_LOTES_FINALIZADOS,
      nomelote: getQueryValue(CADASTRO_QUERIES.nomelote, ''),
    };
  },
};
</script>
