class ToggleNavigationDrawerEvent extends CustomEvent {
  constructor() {
    super(ToggleNavigationDrawerEvent.EVENT);
  }

  static emit() {
    return document.dispatchEvent(new ToggleNavigationDrawerEvent());
  }

  static on(handler) {
    return document.addEventListener(ToggleNavigationDrawerEvent.EVENT, handler);
  }

  static off(handler) {
    return document.removeEventListener(ToggleNavigationDrawerEvent.EVENT, handler);
  }
}

ToggleNavigationDrawerEvent.EVENT = 'app:navigation-drawer';

export default ToggleNavigationDrawerEvent;
