import Dexie from 'dexie';

const db = new Dexie('OLFabrica');

// Tabelas com {Local} não existem na nuvem, e são normalmente usadas apenas para reexibição.

db.version(11).stores({
  usuario: `
  ++id, 
  useidnuvem, 
  usenome, 
  usecpf, 
  usesenha, 
  usedataalteracao
  `,
  apontamentoproducaolocal: `
  ++id,
  appidnuvem,
  appordemproducao,
  appdata, applote,
  appturno,
  appprodutodescricao,
  appgranulometria,
  appdatainicio,
  apptimeinicio,
  appquantidade,
  appembalagem,
  appdescricaoembalagem, 
  appquantidadeembalagem,
  apploteembalagem,
  applocalarmazenamento,
  appdescricaolocalarmazenamento,
  appdatatermino,
  apptimetermino,
  appestado
  `,
  apontamentoproducao: `
  ++id,
  appidnuvem,
  appidproducao,
  appidaberturalote,
  appidturno,
  appidproduto,
  appdatainicio,
  appquantidade,
  appquantidadeembalagem,
  appidaberturaloteembalagem,
  appidapontamentonuvemlocal,
  appidlocalarmazenamento,
  appdatatermino,
  appdataalteracao,
  appidapontamentolocal,
  appdatapesagem,
  appobsduplicado,
  appstatus,
  apppeso,
  appidlocaltransferenciaproducao`
  ,
  aberturalote: `
  ++id,
  ablidnuvem,
  ablidaberturalote,
  ablnrocontrole,
  abldescricao,
  ablgranulometria,
  abldataalteracao,
  ablidordemproducao,
  abldataabertura,
  ablidfabrica,
  ablidproduto`
  ,
  turno: `
  ++id,
  tupidnuvem,
  tupidturno,
  tupdataabertura,
  tupdatafechamento,
  tupobservacao,
  tupdataalteracao`
  ,
  localarmazenamento: `
  ++id,
  loaidnuvem,
  loaidlocalarmazenamento,
  loadescricao,
  loadataalteracao,
  loacodigoarmazem,
  loausadoapppesagem`
  ,
  produto: `
  ++id,
  proidnuvem,
  prodescricao,
  proidproduto,
  prodataalteracao,
  progranulometria,
  proidvariedade`
  ,
  fabrica: `
  ++id,
  fabidnuvem,
  fabidfabrica,
  fabdescricao,
  fabdataalteracao`
  ,
  producao: `
  ++id,
  prcidnuvem,
  prcidproducao,
  prcidfabrica,
  prcdatafechamento,
  prcdataabertura,
  prcobservacao,
  prcnoordemproducao,
  prcdataalteracao`
  ,
  variedade: `
  ++id,
  varidnuvem,
  varidvariedade,
  vardescricao,
  vardataalteracao
  `,
});

db.open().then(function (db) {
  console.log('db | aberto: ', db);
}).catch (function (err) {
  console.log('db | erro: ', err)
});

// ORDERPRODUCAO?


export default db;


/* 
OP
DATA
LOTE
TURNO
DESCRICAO - LOTE
GRANULOMETRIA
INICIO PROD
QUANTIDADE
EMBALAGEM
EMBLALAGEM
DESCRICAO - LOTE EMBALAGEM
QUANTIDADE EMBALAGEM
LOCAL ARMAZENAMENTO
DESCRICAO LOCAL
TERMINO PROD
*/