<template>
  <layout-default-appbar filterabl>
      Transferencia Produção
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue';
import { ROUTE_TRANSFERENCIA_PRODUCAO } from '@/constants/ROUTES';

export default {
  name: 'ViewTransferenciaAppBar',

  components: {
    LayoutDefaultAppbar,
  },

    data() {
    return {
      rota: ROUTE_TRANSFERENCIA_PRODUCAO
    }
  }

};
</script>
