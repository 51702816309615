/**
 * @typedef {Object} RemoveItemDialogOptions
 * @property {string} message
 * @property {string} confirmText
 * @property {string} alertTitle
 * @property {string} alertMessage
 * @property {(MouseEvent) => Promise<boolean>} onConfirmHandler
 * @property {(MouseEvent) => Promise<boolean>} onCancelHandler
 */

class RemoveItemDialog extends CustomEvent {
  /**
   * @param {RemoveItemDialogOptions} options
   */
  constructor(options) {
    super(RemoveItemDialog.EVENT, { detail: options });
  }

  /**
   * @param {RemoveItemDialogOptions} options
   */
  static emit(options) {
    return document.dispatchEvent(new RemoveItemDialog(options));
  }

  static on(handler) {
    return document.addEventListener(RemoveItemDialog.EVENT, handler);
  }

  static off(handler) {
    return document.removeEventListener(RemoveItemDialog.EVENT, handler);
  }
}

RemoveItemDialog.EVENT = 'app:remove-item-dialog';

export default RemoveItemDialog;
