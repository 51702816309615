<template>
  <div>
    <div class="container" v-if="!loading">
      <new-text-field v-if="false" label="Fabrica" v-model="fabrica"
      outlined background-color="white" hide-details>
      </new-text-field>
      <div v-for="( item, index) in producoes" :key="index">
        <div class="card-wrapper">
          <v-card class="card-secador" @click="$_openLotes(item)"> 
            <v-icon v-if="(item.prcdatafechamento)" 
            class="icon" large>mdi-cog</v-icon>
            <v-icon class="icon" v-else large>mdi-cog</v-icon>
            <span class="numero">{{item.prcidproducao}}</span>
            <v-divider></v-divider>
            <div class="linha-menor">
              <span> Ordem Produção: </span>
              <span> {{item.prcidproducao}} </span>
            </div>
              <v-divider></v-divider>
            <div class="linha-menor">
              <span> Apontamentos: </span>
              <span> {{item.quantapontamento}} </span>
            </div>
              <v-divider></v-divider>
            <div v-if="(item.prcdatafechamento)" class="linha-fechado"> <b> FINALIZADO </b> </div>
            <div v-else class="linha-aberto"> <b> EM PRODUÇÃO </b> </div> 
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import deepEquals from '@/utils/deep-equals';

import { getQueryValue, setQueryValue } from '@/services/query-string';

import { ABASTECIMENTO_QUERIES, LOTES_QUERIES } from '@/constants/QUERY_FILTERS';

import { ROUTE_CADASTRO, ROUTE_LOTE } from '@/constants/ROUTES';

import { CADLOTE_STORAGE, OPLOCAL_STORAGE } from '@/constants/STORAGE';

import NewTextField from '@/components/core/new-text-field/Index.vue';

import db from '@/plugins/dexie';

// import AppCardAtividadeSkeleton from '../../components/app/card/atividade/Skeleton.vue';
import { SetupWebSocket } from '../../utils/ws/websocket';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewFrota',

  components: {
    NewTextField,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_CADASTRO, 
      ],

      loading: true,

      producoes: [],

      intervalId: [],

      src: "/img/et.jpg",

      filters: {
        createdFrom: getSessionStorage(CADLOTE_STORAGE.createFrom, null),
        createdBefore: getSessionStorage(CADLOTE_STORAGE.createBefore, null),
      },
    };
  },

  methods: {
    $_load() {
      this.loading = true;
      const emAndamento = getSessionStorage(CADLOTE_STORAGE.frota);
      this.retomar = !!(emAndamento);
      try {
        const promiseBanco = db.producao.orderBy('prcidproducao').toArray();
        promiseBanco.then((item) => {
          for (let i = 0; i < item.length; i++) {
            const ordemPromise = db.apontamentoproducaolocal.where({ appordemproducao: item[i].prcidproducao }).toArray();
            ordemPromise.then((ordem) => {
              item[i].quantapontamento = ordem.length;
              this.producoes.push(item[i]);
              if (this.producoes.length >= item.length) {
                this.loading = false;
              }
            });
          }
        })

      } catch (err) {
        console.log(err);
      }
    },

    async $_openLotes(item) {
      setQueryValue(LOTES_QUERIES.idordemproducao, item.prcidproducao);
      sessionStorage.setItem(OPLOCAL_STORAGE.idop, item.prcidproducao);
      // setQueryValue(CADASTRO_QUERIES.idproducao, nome.id);
      this.$router.push({
        ...this.$route,
        name: ROUTE_LOTE.name,
      });
    },
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {

        const filters = {
          createdFrom: getQueryValue(ABASTECIMENTO_QUERIES.createdFrom, this.filters.createdFrom),
          createdBefore: getQueryValue(
            ABASTECIMENTO_QUERIES.createdBefore,
            this.filters.createdBefore,
          ),
        };

        if (!deepEquals(this.filters, filters)) {
          this.filters.createdFrom = filters.createdFrom;
          this.filters.createdBefore = filters.createdBefore;

          if (this.$refs.lista) {
            this.$_load();   
          }
        }
      },
    },

    options: {
      deep: true,
      handler() {
        // setQueryValue(ABASTECIMENTO_QUERIES.createdFrom, this.filters.createdFrom);
        // setQueryValue(ABASTECIMENTO_QUERIES.createdBefore, this.filters.createdBefore);
      },
    },
  },

  created() {
    this.$_load();
    SetupWebSocket();
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }
  
  .container:first-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-wrapper {
    display: flex;
    margin: 0px;
  }

  .icon {
    height: 70px;
  }

  .linha-aberto {
    background-color: #4CAF50;
    color: white;
  }

  .linha-fechado {
    background-color: #c0372e;
    color: white;
  }

  .linha-menor {
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px 0px 4px;
    font-size: 13px;
  }

  .card-secador {
    width: 40vw;
    text-align: center;
    margin: 10px;
  }

  .numero {
    font-size: 22px;
  }

</style>
