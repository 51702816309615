import db from "@/plugins/dexie"
import moment from "moment-timezone";
import dadosTabelas from "./local/tabelas"
import apirecebimentoweb from '@/services/api-recebimentoweb';
import { USER_STORAGE  } from '@/constants/STORAGE'

function getSessionStorage(key, defaultValue) {
    return sessionStorage.getItem(key) ?? defaultValue;
}

export default async function $_atualizaLocal() {
    const info = dadosTabelas();

    const arrayDataAtualizacao = await Promise.all(Object.entries(info).map(async ([ nome, dados ]) => {

        const dataatt = await db[nome].orderBy(dados.campoatt).last();
        return {
            tabela: dados.nomeapi || nome, 
            tabelaApp: nome,
            data: moment.utc(dataatt || '01-01-1997 13:00:00').format('YYYY-MM-DD HH:mm:ss'), 
        }
    }));

    const retorno = await apirecebimentoweb.post(`all/itemsaatualizar`, arrayDataAtualizacao);
    retorno.data.forEach((item,) => {
        $_geraLog(item);
        $_manipulaTabela(item, info);
    })
}

async function $_geraLog({ data, tabela }) {
    console.log('%cTabela: ' + tabela, 'font-weight: bold; border-top: 1px solid #fff; padding-top: 3px')
    console.log('%c' + data.length + '%c itens para criação ou atualização', 'color: ' +
        (data.length > 0) ? '#f65' : '#5cc', 'color: #fff');
}

async function $_manipulaTabela({ data, tabela }, info) {

    let idnuvem = await db[tabela].toArray();
    idnuvem = await idnuvem.map((item) => {
        return item[`${info[tabela].prefixo}idnuvem`];
    })

    await $_deletaLocal(idnuvem, tabela);

    if (!idnuvem) { idnuvem = []; }
    data.forEach((item) => {
        const itemFormatado = info[tabela].model(item);
        if (idnuvem.includes(item.id)) {
            db[tabela].where({ [`${info[tabela]}idnuvem`]: item.id }).modify(itemFormatado);
        } else {
            db[tabela].add(itemFormatado);
        }
    });
}

async function $_deletaLocal(idnuvemlocal, tabela) {
    let { data } = await apirecebimentoweb.get(`${tabela}/idnuvem`)
    data = await data.map((item) => item.id);


    idnuvemlocal.forEach((itemlocal) => {
        if (!data.includes(itemlocal)) {
            setTimeout(() => {
                console.log(`%cTabela: ${tabela.nome} | ${itemlocal} deletado!`, 'font-weight: bold; border-top: 1px solid #fff; padding-top: 3px');
            }, 100); 
            db[tabela.nome].where({ [`${tabela.prefixo}idnuvem`]: itemlocal }).delete();
        }
    })

}

export async function $_enviaApontamento() {
    let apontamentos = await db.apontamentoproducao.toArray();
    const idusuario = JSON.parse(await getSessionStorage(USER_STORAGE.useidusuario , ''));
    apontamentos.forEach((item) => {
        if (!item.appdataalteracao) {
            if (item.appidnuvem) {
                const payload = {
                    appidordemproducao: item.appidordemproducao,
                    appidaberturaloteembalagem: item.appidaberturaloteembalagem,
                    appquantidadeembalagem: item.appquantidadeembalagem,
                    appdatainicio:  moment(item.appdatainicio),
                    appidlocalarmazenamento: item.appidlocalarmazenamento,
                    appidaberturalote: item.appidaberturalote,
                    appdatatermino: moment(item.appdatatermino),
                    appidturno: item.appidturno,
                    appquantidade: item.appquantidade,
                    appidusuario: idusuario ,
                    appdataalteracao: moment(),
                    id: item.appidnuvem,
                }
                apirecebimentoweb.put(`/apontamentoproducao/${item.appidnuvem}`, payload).then((res) => {
                    const alteracao = (moment.utc(res.data.appdataalteracao).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss'))
                    db.apontamentoproducao.update(item.id, {
                        appdataalteracao: alteracao,
                    });
                });    
            } else {
                const payload = {
                    appidordemproducao: item.appidordemproducao,
                    appidaberturaloteembalagem: item.appidaberturaloteembalagem,
                    appquantidadeembalagem: item.appquantidadeembalagem,
                    appdatainicio:  moment(item.appdatainicio),
                    appidlocalarmazenamento: item.appidlocalarmazenamento,
                    appidaberturalote: item.appidaberturalote,
                    appdatatermino: moment(item.appdatatermino),
                    appidturno: item.appidturno,
                    appquantidade: item.appquantidade,
                    appidusuario: idusuario ,
                    appdataalteracao: moment(),
                }
                apirecebimentoweb.post('/apontamentoproducao', payload).then((res) => {
                    const alteracao = (moment.utc(res.data.appdataalteracao).subtract(3, 'hours').format('YYYY-MM-DDTHH:mm:ss'))
                    db.apontamentoproducao.update(item.id, {
                        appidusuario: res.data.appidusuario,
                        appidnuvem: res.data.id,
                        appdataalteracao: alteracao,
                    });
                });
            }
        }
    }); 
}

export async function SetupEnvioLocal({ silent = false } = {}) {
    if (navigator.onLine) {
        $_atualizaLocal(silent);
        $_enviaApontamento();
    }
    // window.addEventListener('offline', () => {
    //     console.log('Atualmente Offline');
    // });
    // window.addEventListener('online', () => {
    //     console.log('Atualmente Online');
    //     $_atualizaLocal();
    //     $_enviaApontamento();
    // });
}
