<template>
  <app-card-fila-skeleton
  v-bind="$attrs" v-on="$listeners" @click="$_emitter" class="card">
    <template #data> <!-- Nome do cliente -->
      <strong>Data</strong>
    </template>
    <template #quantidade> <!-- Data de atualização do ultimo documento -->
      <strong>Quantidade</strong>
    </template>
    <template #acoes> <!-- Quantidade de documentos relacionados a esse cliente -->
      <strong>Ações</strong>
    </template>
  </app-card-fila-skeleton>
</template>

<script>
import moment from 'moment-timezone';

import AppCardFilaSkeleton from './Skeleton.vue';
// import AppCardFilaCountCard from './CountCard.vue';

export default {
  name: 'AppCardAPagar',

  components: {
    AppCardFilaSkeleton,
    // AppCardFilaCountCard,
  },

  props: {
    value: Object,
  },

  methods: {
    $_emitter() {
      this.$emit('innerClick', this.$props.value);
    },

    $_formataData(data) {
      if (data) {
        return moment(data).format('DD/MM HH:mm');
      }
      return '- - -';
    },
  },
};
</script>
<style scoped>
  .card {
    padding-bottom: 20px;
  }
</style>
