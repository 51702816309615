<template>
  <core-text-field
    v-bind="$attrs"
    v-on="$listeners"
    type="numeric"
    pattern="[0-9]*" inputmode="numeric"
    outlined
    rounded
    v-mask="mask"
  />
</template>

<script>
import CoreTextField from '../text-field/Index.vue';

export default {
  name: 'CoreCpfField',

  components: {
    CoreTextField,
  },

  data() {
    return {
      mask: '###.###.###-##',
    };
  },
};
</script>
