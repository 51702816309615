<template>
  <layout-default-app-bar :back-to="backToValueRoute">
    <div>
      {{ `Leitor QR-Code` }}
    </div>
  </layout-default-app-bar>
</template>

<script>

// import { ROUTE_LOTE } from '@/constants/ROUTES';

import LayoutDefaultAppBar from '@/layouts/default/AppBar.vue';

export default {
  name: 'ViewHomeAppBar',

  components: {
    LayoutDefaultAppBar,
  },

  data() {
    return {
      backToValueRoute: '',
    };
  },

  methods: {
    setConfig() {
      this.backToValueRoute =  sessionStorage.getItem('rota-anterior');
    },
  },

  created() {
    this.setConfig();
  },

};
</script>

<style scoped>
  #icone{
    top: 6%;
    position: relative;
  }

  #texto {
    position: absolute;
    left: 120px;
    bottom: 8px;
    font-size: 24px;
  }
</style>
