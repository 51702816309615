import api from '@/services/api-recebimentoweb';

export default {
namespaced: true,

state: {
    list: [],
},

getters: {
    list: (state) => state.list,
},

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
actions: {
    async find(_, {
        where, ...params
        } = {}) {
            const [{ data }] = await Promise.all([
            api.get('apontamentoproducao/app', { params: { ...where, ...params } }),
        ]);

        return data;
    },

    async findAll(_, { params }) {
        const [{ data }] = await Promise.all([
            api.get(`apontamentoproducao/all/${params.idcliente}`, { params: params.where }),
        ]);

        return data;
    },

    async findByEmpresa(_, { idempresa }) {
        const [{ data }] = await Promise.all([
            api.get(`apontamentoproducao/empresa/${idempresa}`),
        ]);

        return data;
    },

    async findByIdNuvem(_, { id }) {
        const [{ data }] = await Promise.all([
            api.get(`apontamentoproducao/${id}`),
        ]);

        return data;
    },

    async update(_, body) {
        console.log(body);
        const { data } = await api.put(`apontamentoproducao/app/${body.id}`, body);
        return data;
    },

    async create(_, body) {
        console.log(body);
        const { data } = await api.post('apontamentoproducao/app', body);
        return data;
    },

    async aberturaloteUtilizados() {
        const { data } = await api.get('apontamentoproducao/aberturalote');
        return data;
    },

    async updateByNroControle(_, body) {
        const { data } = await api.put(`apontamentoproducao/aberturalote/${body.ablidaberturalote}`, body);
        return data;
    },

    async updateByIdApontamento(_, body) {
        const { data } = await api.put(`apontamentoproducao/transferencia/${body.id}`, body);
        return data;
    },

},

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
    mutations: {
        SET_LIST(state, list) {
            state.list = list ?? [];
        },
    },
};
