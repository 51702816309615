<template>
  <div style="display: none;">
  </div>
</template>

<script>

export default {
  name: 'FormConfig',

  // INPUT FLEXIVEL

  props: {
    /* Vincular um objeto do formulario 

        
    
    */
    formulario: {
      type: Object,
    },
    tabela: { // Tabela do vuex que receba as ações
      type: String,
    },
    substituir: { // Substitui o put por um delete seguido de post
      type: Boolean,
      default: false,
    },
    acaounica: { type: String }, // Realiza a funcao passada aqui ao inves do fluxo normal
    get: { default: null },
    delete: { type: String || Function || Object },
    post: { type: String || Function || Object },
    update: { type: String || Function || Object },
    beforeGet: { type: String || Function },
    beforeDelete: { type: String || Function },
    beforePost: { type: String || Function },
    beforeUpdate: { type: String || Function },
  },

  data() {
    return {
      aprovado: false,
      actions: null,
      tabelas: null,
      params: {
        get: null,
        put: null,
        delete: null,
        post: null,
      },
    };
  },

  methods: {

    async $_submit() {
      if (this.$props.tabela) {
        // eslint-disable-next-line no-unused-vars
        const body = Object.fromEntries(Object.entries(this.formulario).filter(([_, v]) => v != null));
        const idnuvem = sessionStorage.getItem(`id${this.$props.tabela}nuvem`);
        const acaounica = this.$props.acaounica;
        if (acaounica) {
          const res = this.$store.dispatch(`${this.$props.tabela}/${acaounica}`, body);
          await this.$_validaResposta(res);
          return this.aprovado;
        }
        if (idnuvem) {
          body.id = idnuvem;
          if (this.$props.substituir) {
            await this.$_replace(body, this.$props.tabela);
          } else {
            await this.$_update(body, this.$props.tabela);
          }
        } else {
          await this.$_create(body);
        }
        if (this.aprovado) {
          return this.aprovado;
        }
      } else {
        this.tabelas = Object.keys(this.$props.formulario);
        for (let i = 0; i < this.tabelas.length; i += 1) {
          const body = this.$props.formulario[this.tabelas[i]];
          const idnuvem = sessionStorage.getItem(`id${this.tabelas[i]}nuvem`);
          if (idnuvem) {
            body.id = idnuvem;
            if (this.$props.substituir) {
              this.$_replace(body, this.tabelas[i]);
            } else {
              this.$_update(body, this.tabelas[i]);
            }
          } else {
            this.$_create(body, this.tabelas[i]);
          }
        }
      }
      if (this.aprovado) {
        return this.aprovado;
      }
      return this.aprovado;
    },

    async $_del() {
      if (this.$props.tabela) {
        const body = this.$props.formulario;
        const idnuvem = sessionStorage.getItem(`id${this.$props.tabela}nuvem`);
        if (idnuvem) {
          body.id = idnuvem;
          await this.$_delete(body, this.$props.tabela);
        }
        if (this.aprovado) {
          this.$_navigateTo();
        }
      }
    },

    // Chain (fazer uma serie de posts se necessario)
    async $_create(body) {
      // if (this.$props.beforePost) {
      //   this.$_before(this.$props.beforePost())
      //   console.log('C R E A T E | Bloqueado');
      //   return;
      // }
      console.log('C R E A T E', body);
      const res = this.$store.dispatch(this.actions.$_postAPI, body);
      await this.$_validaResposta(res);
    },

    async $_find() {
      console.log('R E A D');
      if (this.$props.get) {
        console.log(this.$props.get.params, this.actions.$_getAPI, 'aaasxax');
        const res = this.$store.dispatch(this.actions.$_getAPI, {
          params: this.$props.get.params,
        });
        await this.$_validaResposta(res);
      } else {
        console.log('ha?');
        const res = this.$store.dispatch(this.actions.$_getAPI);
        console.log(res);
        await this.$_validaResposta(res);
      }
    },

    async $_findById(idnuvem) {
      console.log('R E A D  B Y  I D');
      const res = this.$store.dispatch(this.actions.$_getByIdAPI, { id: idnuvem });
      await this.$_validaResposta(res);
    },

    async $_update(body, tabela) {
      console.log('U P D A T E');
      const res = this.$store.dispatch(`${tabela}/update`, body);
      await this.$_validaResposta(res);
    },

    async $_replace(body, tabela) {
      await this.$_delete(body, tabela);
      await this.$_create(body, tabela);
    },

    async $_delete(body, tabela) {
      console.log('D E L E T E');
      const res = this.$store.dispatch(`${tabela}/delete`, body);
      await this.$_validaResposta(res);
    },

    async $_unique(body) {
      console.log('U N I Q U E');
      const where = { [this.$props.unique]: body[this.$props.unique] };
      const res = this.$store.dispatch(this.actions.$_getAPI, { where });
      await this.$_validaResposta(res);
    },

    async $_before(method) {
      let validacao = null;
      if (method) {
        if (typeof method === 'string') {
          validacao = this.$store.dispatch(`${this.$props.tabela}/${method}`);
        } else if (typeof method === 'function') {
          validacao = await method();
        }
      }
      return validacao;
    },

    async $_load() {
      if (this.$props.tabela) {
        const idnuvem = sessionStorage.getItem(`id${this.$props.tabela}nuvem`);
        if (idnuvem) {
          await this.$_findById(idnuvem);
        } else {
          await this.$_find(this.$props.tabela);
        }
        if (this.aprovado) {
          return this.aprovado;
        }
      }
      return null;
    },

    async $_validaResposta(res) {
      this.aprovado = res;
    },
  },

  created() {
    this.actions = {
      $_getAPI: `${this.$props.tabela}/find`,
      $_deleteAPI: `${this.$props.tabela}/delete`,
      $_postAPI: `${this.$props.tabela}/create`,
      $_updateAPI: `${this.$props.tabela}/update`,
      $_getByIdAPI: `${this.$props.tabela}/findByIdNuvem`,
    };
    const { get } = this.$props;
    if (get) {
      if (typeof get === 'string') {
        const barra = get.indexOf('/');
        if (barra > -1) {
          this.actions.$_getAPI = `${get}`;
        } else {
          this.actions.$_getAPI = `${this.$props.tabela}/${get}`;
        }
      } else if (typeof get === 'object') {
        console.log('bbb');
        this.actions.$_getAPI = `${this.$props.tabela}/${get.method}`;
      } else if (typeof this.$props.get === 'function') {
        // *
      }
    }
    if (this.$props.delete) {
      this.actions.$_deleteAPI = `${this.$props.tabela}/${this.$props.delete}`;
    }
    if (this.$props.post) {
      this.actions.$_postAPI = `${this.$props.tabela}/${this.$props.post}`;
    }
    if (this.$props.update) {
      this.actions.$_updateAPI = `${this.$props.tabela}/${this.$props.update}`;
    }
  },
};

</script>
