<template>
  <layout-default-dialog-appbar :back-to="rotalote" withDialog>
    {{nomelote}}
  </layout-default-dialog-appbar>
</template>

<script>
import LayoutDefaultDialogAppbar from '@/layouts/default/DialogAppBar.vue';

import {
  getQueryValue,
} from '@/services/query-string';


import { ROUTE_LOTE } from '@/constants/ROUTES';
import { CADASTRO_QUERIES } from '@/constants/QUERY_FILTERS';

export default {
  name: 'ViewFrotaAppBar',

  components: {
    LayoutDefaultDialogAppbar,
  },

  data() {
    return {
      rotalote: ROUTE_LOTE,
      nomelote: getQueryValue(CADASTRO_QUERIES.nomelote, ''),
    };
  },
};
</script>
