
// db.aberturalote.add();
// db.turno.add();
// db.localarmazenamento.add();
// db.produto.add();
// db.fabrica.add();
// db.producao.add();

import db from '@/plugins/dexie';

const aAberturalote = {
    ablidnuvem: '',
    ablidaberturalote: 1,
    ablnrocontrole: 'LEC0007/23',
    abldescricao: 'PRODUTO_TESTE',
    ablgranulometria: '50/60',
    abldataaatualizacao: '2023-01-14 00:00:00+00',
    ablidordemproducao: 1,
}

const bAberturalote = {
    ablidnuvem: '',
    ablidaberturalote: 1,
    ablnrocontrole: 'LEC0008/23',
    abldescricao: 'PRODUTO_B',
    ablgranulometria: '30/70',
    abldataaatualizacao: '2023-01-14 00:00:00+00',
    ablidordemproducao: 1,
}

const cAberturalote = {
    ablidnuvem: '',
    ablidaberturalote: 1,
    ablnrocontrole: 'LEC0011/23',
    abldescricao: 'PRODUTO_NOVO',
    ablgranulometria: '20/80',
    abldataaatualizacao: '2023-01-14 00:00:00+00',
    ablidordemproducao: 2,
}

const aTurno = {
    tupidnuvem: '',
    tupidturno: 5,
    tupdataabertura: '2023-01-14 00:00:00+00',
    tupdatafechamento: '2023-01-14 00:00:00+00',
    tupobservacao: '',
    tupdataatualizacao: '2023-01-14 00:00:00+00',
}

const bTurno = {
    tupidnuvem: '',
    tupidturno: 2,
    tupdataabertura: '2023-01-14 00:00:00+00',
    tupdatafechamento: '2023-01-14 00:00:00+00',
    tupobservacao: '',
    tupdataatualizacao: '2023-01-14 00:00:00+00',
}

const aLocalArmazenamento = {
    loaidnuvem: '',
    loaidlocalarmazenamento: 1,
    loadescricao: 'ARMAZEM D1',
    loadataatualizacao: '2023-01-14 00:00:00+00',
}

const bLocalArmazenamento = {
    loaidnuvem: '',
    loaidlocalarmazenamento: 1,
    loadescricao: 'ARMAZEM B4',
    loadataatualizacao: '2023-01-14 00:00:00+00',
}

const aProduto = {
    proidnuvem: '',
    proidproduto: 1,
    prodescricao: 'AMENDOIM BICA CORRIDA',
    procodigo: '2042',
    provalor: '',
    prodataatualizacao: '2023-01-14 00:00:00+00',
}

const aFabrica = {
    fabidnuvem: '',
    fabidfabrica: 1,
    fabdescricao: 'BENEFÍCIO',
    fabdataatualizacao: '2023-01-14 00:00:00+00',
}

const aProducao = {
    prcidnuvem: '',
    prcidproducao: 1,
    prcidfabrica: 1,
    prcordemproducao: 1,
    prcdataabertura: '2023-01-14 00:00:00+00',
    prcdataproducao: '2023-01-14 00:00:00+00',
    prcdaaatualizacao: '2023-01-14 00:00:00+00',
}

const bProducao = {
    prcidnuvem: '',
    prcidproducao: 2,
    prcidfabrica: 2,
    prcordemproducao: 1,
    prcdataabertura: '2023-01-14 00:00:00+00',
    prcdataproducao: '2023-01-14 00:00:00+00',
    prcdaaatualizacao: '2023-01-14 00:00:00+00',
}

export default function $_itensTesteTabelaLocal() {
    db.aberturalote.add(aAberturalote);
    db.turno.add(aTurno);
    db.localarmazenamento.add(aLocalArmazenamento);
    db.produto.add(aProduto);
    db.fabrica.add(aFabrica);
    db.producao.add(aProducao);
    db.aberturalote.add(bAberturalote);
    db.aberturalote.add(cAberturalote);
    db.turno.add(bTurno);
    db.localarmazenamento.add(bLocalArmazenamento);
    db.producao.add(bProducao);
}

// aberturalote: `++id, ablidnuvem, ablnrocontrole, abldescricao, ablgranulometria, abldataatualizacao`,
// turno: `++id, tupidnuvem, tupdataabertura, tupdatafechamento, tupobservacao, tupdataatualizacao`,
// localarmazenamento: `++id, loaidnuvem, loadescricao, loaadataaatualizacao`,
// produto: `++id, proidnuvem, prodescricao, procodigo, provalor, prodataatualizacao`,
// fabrica: `++id, fabidnuvem, fabdescricao, fabdataatualizacao`,
// producao: `++id, prcidnuvem, prcidfabrica, prcordemproducao, prcdataabertura, prcdataproducao,
// prcdaaatualizacao`,