<template>
  <div>
    <div v-if="lotes.length > 0 && !loading">
      <h3 class="center">{{nro}}</h3>
      <header-card>
      </header-card>
      <div v-for="item in lotes" :key="item.id">
        <card-lote :value="item"
        @innerClick="$_proximaLista(true, $event)"
        @visualizar="$_openReadonly(item)"
        @editar='$_openEdicao(item)'>
        </card-lote>
      </div>
    </div>
    <div v-else class="center">
      <br/>
      <h3>Este lote não possui apontamentos fechados</h3>
    </div>
  </div>
</template>

<script>
import { setQueryValue } from '@/services/query-string';
import { ROUTE_PRODUCAO_READONLY, ROUTE_PRODUCAO_CADASTRO } from '@/constants/ROUTES'
import { OPLOCAL_STORAGE } from '@/constants/STORAGE'
import { CADASTRO_QUERIES } from '@/constants/QUERY_FILTERS';
import db from '@/plugins/dexie';
import CardLote from '@/components/app/card/padrao/Index.vue'
import HeaderCard from '@/components/app/card/header/Index.vue';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'LotesFinalizados',
  data() {
    return {
      loading: true,
      lotes: null,
    }
  },

  components: {
    CardLote,
    HeaderCard,
  },

  methods: {
    async $_setup() {
      this.nro = await getSessionStorage(OPLOCAL_STORAGE.nro, null);
      this.lotes = await db.apontamentoproducaolocal.where({ 
        appestado: 'F', applote: this.nro }).toArray();
      this.loading = false;
    },

    async $_openReadonly(item) {
      sessionStorage.setItem(OPLOCAL_STORAGE.idapontamento, item.id);
      this.$router.push({ 
        ...this.$route,
        name: ROUTE_PRODUCAO_READONLY.name,
      });
    },

    async $_openEdicao(item) {
      sessionStorage.setItem(OPLOCAL_STORAGE.idapontamento, item.id);
      sessionStorage.setItem(OPLOCAL_STORAGE.emedicao, true);
      setQueryValue(CADASTRO_QUERIES.idlocal, item.id);
      this.$router.replace({ 
        ...this.$route,
        name: ROUTE_PRODUCAO_CADASTRO.name,
        idlocal: item.id,
      });
    },
  },

  created() {
    this.$_setup();
  }
}
</script>

<style scoped>
  .center {
    text-align: center;
    color: white;
    background-color: #4CAF50;
  }
</style>
