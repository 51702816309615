<template>
  <app-card-fila-skeleton
  v-bind="$attrs" v-on="$listeners" @click="$_emitter">
    <template #data> <!-- Icone card -->
      <div class="data">{{$_formataData(value.appdata, 'DD/MM HH:mm')}}</div>
    </template>
    <template #quantidade> <!-- Data de atualização do ultimo documento -->
      <div class="quantidade">{{value.appquantidade}}Kg</div>
    </template>
    <template #icones> <!-- Quantidade de documentos relacionados a esse cliente -->
      <v-icon @click.stop="$_emitter('visualizar')"
      class="icone-esquerda iconHover">mdi-magnify</v-icon>
      <v-icon @click.stop="$_emitter('editar')" class="iconHover">mdi-text-box-edit-outline</v-icon>
    </template>
  </app-card-fila-skeleton>
</template>

<script>
import moment from 'moment-timezone';

import AppCardFilaSkeleton from './Skeleton.vue';
// import AppCardFilaCountCard from './CountCard.vue';

export default {
  name: 'AppCardAPagar',

  components: {
    AppCardFilaSkeleton,
    // AppCardFilaCountCard,
  },

  props: {
    value: Object,
  },

  data() {
    return {
      checkbox: false,
    };
  },

  methods: {
    $_emitter(param) {
      this.$emit(param, this.$props.value.clinome);
    },

    $_formataData(data, formato) {
      return moment.tz(data, 'America/Sao_Paulo').local().format(formato);
    },

    $_formataDataMenor(data) {
      // moment.locale('pt-br');
      return moment.tz(data, 'America/Sao_Paulo').add(3, 'hours').format('DD/MM');
    },
  },
};
</script>
<style scoped>
  .nome {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: max(15px, 1.1vw);
  }

  .icone-esquerda {
    margin-right: 10px;
  }

  .iconHover:hover {
    color: rgb(156, 231, 221);
  }

  .data {
    font-size: 13px;
  }

  .quantidade {
    font-size: 13px;
  }

</style>
