<template class="teste">
  <div class="black-screen">
    <div>
      <qrcode-stream
      @decode="onDecode">
      </qrcode-stream>
    </div>
    <footer class="footer" v-if="footer">{{mensagem}}</footer>
    <v-btn v-if="botaoteste" @click="onDecode(nroteste)">{{nroteste}}</v-btn>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import { OPLOCAL_STORAGE } from '@/constants/STORAGE';

export default {
  name: 'QRView',

  components: {
    QrcodeStream,
  },

  data() {
    return {
      backToValueRoute: '',
      tipo: this.$route.params.tipo,
      footer: false,
      mensagem: 'Posicione o QR-Code em frente a câmera',
      nroteste: 'EMB0001/235'
    };
  },

  methods: {
    async onDecode(content) {
      const newContent = content.replace("-", "");
      const indexBarra = content.indexOf('/');
      console.log(indexBarra);
      if (newContent[indexBarra + 3]) {
        const digitos = `${newContent[indexBarra + 3]}${newContent[indexBarra + 4] || ''}`
        sessionStorage.setItem(OPLOCAL_STORAGE.numerobag, digitos);
        if (newContent[indexBarra + 4]) {
          sessionStorage.setItem(OPLOCAL_STORAGE.qr, newContent.slice(0, -2));
        } else {
          sessionStorage.setItem(OPLOCAL_STORAGE.qr, newContent.slice(0, -1));
        }
        this.$router.back();
        return;
      } 
      sessionStorage.setItem(OPLOCAL_STORAGE.qr, newContent);
      this.$router.back();
    },
  },

  created() {
    setTimeout(() => {
      this.footer = true;
      sessionStorage.setItem(OPLOCAL_STORAGE.qr, null);
    }, 1100);
    this.botaoteste = (process.env.NODE_ENV !== 'production');
  },
};

</script>

<style scoped>

  .black-screen {
    background-color: #343434;
    height: 100%;
  }

  .footer {
    background-color: #343434;
    display: flex;
    justify-content: center;
    color: #eee;
    z-index: 2;
    flex: 1;
  }

  .teste {
    background-color: #343434;
  }

</style>>
