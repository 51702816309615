export const TOKEN = 'auth:token';

export const TOKENLOCAL = {
  tokenlocal: 'auth:tokenlocal'
}

export const MODULOS_STORAGE = {
  modulos: 'permitidos',
};

export const USER_STORAGE = {
  usesenha: 'use-senha',
  localtoken: 'localtoken',
  useidnuvem: 'user:useidnuvem',
  useidusuario: 'user:useidusuario',
  userId: 'id-usuario',
};

export const CADLOTE_STORAGE = {
  quantkg: 'cadlote:operacao',
  quantsaca: 'cadlote:frota',
  numerosublote: 'cadlote:operadora',
  embalagem: 'cadlote:odometro',
};

export const CADPRODUCAO_STORAGE = {
  quantkg: 'cadlote:operacao',
  quantsaca: 'cadlote:frota',
  numerosublote: 'cadlote:operadora',
  embalagem: 'cadlote:odometro',
};

export const FROTA_STORAGE = {
  tipo: 'frota:tipo',
  placa: 'frota:placa',
};

export const OPERADOR_STORAGE = {
  tipo: 'operador:tipo',
};

export const ORIGEM_STORAGE = {
  parametro: 'apagar:parametro',
  page: 'apagar:page',
  sort: 'apagar:sort',
  search: 'apagar:search',
  textofiltro: 'apagar:textofiltro',
};

export const OPLOCAL_STORAGE = {
  idapontamento: 'idapontamento',
  idop: 'idop',
  nro: 'nro',
  emedicao: 'emedicao',
  qr: 'qr',
  numerobag: 'numerobag',
};

export const TRANSFERENCIA_STORAGE = {
  idapontamento: 'idapontamento',
  idop: 'idop',
  nro: 'nro',
  emedicao: 'emedicao',
  qr: 'qr',
  numerobag: 'numerobag',
};

// apontamentoproducao: `++id, appidnuvem, appdata, applote, appturno, appdescricao, apgranulometria, 
// appdatainicio, appquantidade, appembalagem, appdescricaoembalagem, appquantidadeembalagem,
// applocalarmazenamento, appdescricaolocalarmazenamento, appdatatermino`
// });
