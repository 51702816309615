<template>
  <v-card class='card card-wrapper pa-0' rounded="lg" v-bind="$attrs" v-on="$listeners">
    <v-card-text class="text pt-1 pr-10 pl-10">
      <div class="data">
        <slot name="data">
          <v-skeleton-loader width="20%" class="pt-1" type="text" />
        </slot>
      </div>
      <div class="quantidade">
        <slot name="quantidade">
          <v-skeleton-loader width="20%" class="pt-1" type="text" />
        </slot>
      </div>
      <div class="acoes">
        <slot name="acoes">
          <v-skeleton-loader width="20%" class="pt-1" type="text" />
        </slot>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AppCardAPagarSkeleton',

  props: {
    actions: Boolean,
  },
};
</script>

<style  scoped>

  .text {
    display: flex;
    justify-content: space-between;
  }

  .card-wrapper {
    margin-bottom: 10px;
  }

  .card {
    background-color: #61c264;
    height: 26px;
    width: 60vw;
    font-size: 1.2rem;
  }

  .card:hover {
    cursor:default;
  }

  .data {
    width: 26%;
    text-align: left;
  }

  .quantidade {
    width: 30%;
    text-align: left;
  }

  .acoes {
    width: 30%;
    text-align: right;
  }

  @media screen and (max-width: 800px) {

    .card {
      width: 100vw;
      transition: 0.2s;
      padding: 18px 30px 18px 0px;
      font-size: 1rem;
    }
  }

</style>
