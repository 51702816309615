export default function deepEquals(a, b) {
  if (a === b) return true;

  if (typeof a !== 'object') return false;
  if (typeof b !== 'object') return false;

  const aEntries = Object.entries(a);
  const bEntries = Object.entries(b);

  if (aEntries.length !== bEntries.length) return false;

  for (let i = 0; i < aEntries.length; i += 1) {
    const [key, value] = aEntries[i];

    if (!deepEquals(value, b[key])) return false;
  }

  return true;
}
