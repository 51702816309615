<template>
  <core-view class="flex-column" fill-height>
    <template>
    <div class="center" v-if="!loading">
        <form class="teste">
          <div class="linha">
            <v-text-field label="OP" v-model="formulario.ordemproducao"
            outlined readonly />
            <v-text-field label="Data" v-model="formulario.dataordemproducao"
            outlined readonly />
          </div>
          <div class="linha">
            <v-text-field label="Lote" v-model="formulario.lote"
              outlined
              readonly/>
            <v-text-field label="Turno" v-model="formulario.turno"
              outlined
              readonly/>
          </div>
          <div class="linha-justificada">
            <v-text-field label="Descrição" v-model="formulario.descricao"
            readonly
            outlined />
          </div>
          <div class="linha-justificada">
            <v-text-field label="Granulometria" v-model="formulario.granulometria"
            outlined readonly />
          </div>
          <div class="linha">
            <v-text-field
              v-model="formulario.datainicioprod" readonly
              outlined label="Data Inicio Produção"
            />
            <v-text-field
              v-model="formulario.timeinicioprod" readonly
              outlined label="Tempo Inicio Produção"
            />
          </div>
          <div class="linha-menor">
            <v-text-field label="Quantidade" v-model="formulario.quantidade"
            outlined number readonly/>
          </div>
          <div class="linha">
            <v-text-field label="Embalagem"  outlined
            v-model="formulario.embalagem"
            readonly/>
            <v-text-field label="Lote"  outlined
            v-model="formulario.loteembalagem" readonly/>
          </div>
          <div class="linha">
            <v-text-field label="Descrição" v-model="formulario.embalagemdesc"
              outlined readonly />
          </div>
          <div class="linha-menor">
            <v-text-field label="Quantidade" v-model="formulario.quantidadeembalagem"
            outlined number readonly/>
          </div>
          <div class="linha">
            <div class="reduzido">
              <v-text-field label="Local"
              v-model="formulario.localarmazenamento" readonly outlined
              />
            </div>
            <v-text-field label="Descrição" v-model="formulario.localarmazenamentodesc"
              outlined readonly />
          </div>
          <div class="linha">
            <v-text-field clearable outlined
              v-model="formulario.dataterminoprod"
              label="Data Termino Produção"
              readonly
            />
            <v-text-field
              v-model="formulario.timeterminoprod" readonly
              outlined label="Tempo Termino Produção"
            />
          </div>
      </form>
    </div>
 </template>

</core-view>
</template>

<script>
// import errorHandler from '@/utils/error-handler';

// import {
//   ROUTE_LOTE,
// } from '@/constants/ROUTES';

import { OPLOCAL_STORAGE } from '@/constants/STORAGE';

import CoreView from '@/components/core/view/Index.vue';

import moment from 'moment-timezone';

import db from '@/plugins/dexie';

import { SetupWebSocket } from '../../utils/ws/websocket';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewCadastroProducao',

  components: {
    CoreView,
  },

  data() {
    return {
      rotaFiltros: [
      ],

      formulario: {
        ordemproducao: 'OPB0001/23',
        dataordemproducao: moment().format('YYYY-MM-DD'),
        lote: null,
        turno: null,
        descricao: null,
        granulometria: null,
        datainicioprod: null,
        timeinicioprod: null,
        quantidade: null,
        embalagem: null,
        loteembalagem: null,
        embalagemdesc: null,
        quantidadeembalagem: null,
        localarmazenamento: null,
        localarmazenamentodesc: null,
        dataterminoprod: null,
        timeterminoprod: null,
      },

      loading: true,

      mask: '##:##',
    };
  },

  methods: {
    async $_findDb() {
      const idapontamento = await getSessionStorage(OPLOCAL_STORAGE.idapontamento, '');
      try {
        const local = await db.apontamentoproducaolocal.where({ id: Number(idapontamento) }).first();
        this.$_setupCampos(local);
      } catch(e) {
        console.log(e);
      }
    },

    async $_setupCampos(local) {
        this.formulario.ordemproducao = 'OPB0001/23';
        this.formulario.dataordemproducao = moment().format('YYYY-MM-DD');
        this.formulario.lote = local.applote;
        this.formulario.descricao = local.appdescricao;
        this.formulario.granulometria = local.appgranulometria;
        this.formulario.turno = local.appturno;
        this.formulario.datainicioprod = local.appdatainicio;
        this.formulario.timeinicioprod = local.apptimeinicio;
        this.formulario.quantidade = local.appquantidade;
        this.formulario.embalagem = local.appembalagem;
        this.formulario.embalagemdesc = local.appdescricaoembalagem;
        this.formulario.loteembalagem = local.apploteembalagem;
        this.formulario.quantidadeembalagem = local.appquantidadeembalagem;
        // localarmazenamento
        this.formulario.localarmazenamento = local.applocalarmazenamento
        this.formulario.localarmazenamentodesc = local.appdescricaolocalarmazenamento;
        this.formulario.dataterminoprod = local.appdatatermino;
        this.formulario.timeterminoprod = local.apptimetermino;
        console.log(this.formulario);
        this.loading = false;
    },
  },

  created() {
    this.$_findDb();
    SetupWebSocket();
  },

};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .linha {
    display: flex;
        /* border: 1px solid red; */
  }

  .linha-menor {
    display: flex;
    width: 50%;
        /* border: 1px solid red; */
  }

  .linha-justificada {
    display: flex;
    justify-content: space-around;
  }

  .linha-local {
    display: flex;
    justify-content: space-around;
  }

  .div-inferior {
    display: flex;
    justify-content: space-between;
  }

  .botaoinferior {
    color: white;
  }

  .container-data > * {
    margin: 0px 10px 0px 10px;
  }

  .reduzido {
    width: 35%;
  }

</style>
