<template>
    <core-view class="flex-column" fill-height>
          <!-- <v-btn height=45 color="green botaoinferior" @click="$_itensTesteTabelaLocalCaller()">
            Preencher
          </v-btn> -->
    </core-view>
</template>

<script>
import { mapActions } from 'vuex';

// import errorHandler from '@/utils/error-handler';

import CoreView from '@/components/core/view/Index.vue';

import $_itensTesteTabelaLocal from '@/utils/templatetabelas'

import { TOKEN, USER_STORAGE  } from '@/constants/STORAGE';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

const VALUE_LABELS = [
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb',
];

export default {
  name: 'ViewHome',

  components: {
    CoreView,
  },

  data() {
    const value = [
      82000,
      75000,
      115000,
      45000,
      80000,
      67000,
    ];

    return {
      labels: value.map((val, index) => `${VALUE_LABELS[index]} ${val}`),
      value,
      mensagem: '',
      chat: [],
      formatado: [],
      wsConnection: null,
      websocket: {
        connectionId: null,
        primeiraconexao: true,
        conectado: false,
      },
      online: false,
    };
  },

  methods: {
    ...mapActions({
      $_recebimentosFind: 'recebimento/find',
      $_findFila: 'fila/find',
      $_findUserId: 'users/findByWebId'
    }),

    $_itensTesteTabelaLocalCaller() {
      $_itensTesteTabelaLocal();
    },

    async $_setupIdUsuario() {
      const token = JSON.parse(await getSessionStorage(TOKEN , ''));
      const data = await this.$_findUserId({ id: token.userId });
      sessionStorage.setItem(USER_STORAGE.useidusuario, data.useidusuario);
    }
  },

  created() {
    this.$_setupIdUsuario();
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

</style>
